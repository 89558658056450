<template>
  <b-container class="page-container">
    <Breadcrumbs :bread-crumbs="[{ page: 'index', title: 'Login', path: '/' }]" currentpage="Wachtwoord vergeten" />
    <h1>Uw wachtwoord vergeten?</h1>
    <p>
      Vul hieronder uw emailadres in om uw wachtwoord te herstellen
    </p>
    <b-alert v-if="resetLinkSend" show variant="warning">
      Per mail ontvangt u een herstel link om een nieuw wachtwoord in te
      stellen, dit mits uw emailadres binnen ons systeem bestaat.
    </b-alert>
    <b-row>
      <b-col sm="12" class="mb-3"></b-col>

      <b-col sm="12" md="6" lg="4">
        <b-card>
          <b-form @submit.prevent="resetPasswordSubmit">
            <b-form-group
              id="input-group-1"
              label="Emailadres"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.email"
                type="text"
                required
                :disabled="resetLinkSend"
              ></b-form-input>
            </b-form-group>
            <b-row>
              <b-col cols="12">
                <b-button
                  class="mb-2"
                  type="submit"
                  :disabled="resetLinkSend"
                  variant="primary"
                  >Wachtwoord opvragen
                  <font-awesome-icon
                    v-if="requesting"
                    class="fa-spin"
                    far
                    icon="spinner"
                /></b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { requestPasswordReset } from '@/services/AuthorizationService'
import Breadcrumbs from '@/components/header/TheBreadcrumbs'
export default {
  components: { Breadcrumbs },
  data() {
    return {
      form: {
        email: ''
      },
      resetLinkSend: false,
      requesting: false
    }
  },
  computed: {
    frontendURL: function() {
      return process.env.VUE_FRONTEND_URL
    }
  },
  methods: {
    async resetPasswordSubmit() {
      this.requesting = true

      const result = await requestPasswordReset({
        email: this.form.email
      })

      if (result.data != null) {
        this.resetPasswordSubmit = true
      }

      this.resetLinkSend = true
      this.requesting = false
    }
  }
}
</script>
<style scoped lang="scss">
a.btn {
  text-decoration: none;
}
</style>
